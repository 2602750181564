<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <!-- 高級搜索 -->
        <b-card v-if="isShowCard">
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition">
            <b-row>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="采购订单编号"
                    label-for="order_no"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="order_no"
                      size="sm"
                      v-model="condition.order_no"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="销售权"
                    label-for="purchase_team_id"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      :options="getCodeOptions('purchase_team')"
                      v-model="condition.purchase_team_id"
                      name="purchase_team_id"
                      class="select-size-sm"
                      placeholder="请选择销售权团队"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <modal-select
                    label="创建人"
                    type="input"
                    v-on:change="fromChildren($event,['full_name','creator'],condition)"
                    :params="['full_name','creator']"
                    modalName="创建人"
                    placeholder="点击选择创建人"
                    v-model="condition.full_name"
                >
                </modal-select>
              </b-col>

              <b-col md="4">
                <modal-select
                    label="供应商"
                    type="input"
                    v-on:change="fromChildren($event,['company_name','supplier_id'],condition)"
                    :params="['company_name','supplier_id']"
                    modalName="供应商"
                    placeholder="点击选择供应商"
                    v-model="condition.company_name"
                >
                </modal-select>
              </b-col>

              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="备采类型"
                    label-for="stockup_type"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      :options=stock_type_code
                      v-model="condition.stockup_type"
                      name="stockup_type"
                      class="select-size-sm"
                      placeholder="请选择备采类型"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="备采单状态"
                    label-for="status"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      :options="getCodeOptions('stockup_status')"
                      v-model="condition.status"
                      name="status"
                      class="select-size-sm"
                      placeholder="请选择备采单状态"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>


              <!--查询、重置按钮-->
              <b-col
                  cols="12"
                  md="12"
                  class=" demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #row-details="row">
          <purchaseStockupItemList :ps_id="row.item.ps_id" :stockup_type="row.item.ext.stockup_type"></purchaseStockupItemList>
        </template>
        <template #cell(ps_id)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                icon="MinusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==true"
            />
            <feather-icon
                icon="PlusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          #{{ data.item.id }}
        </template>

        <template #cell(order_no)="data">

          <!--[#{{data.item.order_id}}]-->
          <b-link
              :to="oaMenu.changing.includes(data.item.ext.order_status) ? { name: 'apps-purchaseorder-view', query: { id: data.item.order_id} }
                :{ name: 'apps-purchaseorder-edit', query: { id: data.item.order_id },params:{readonly:1} }"
              target="_blank"
          >
            {{data.value}}
          </b-link>
        </template>

        <template #cell(status)="{value}">
          <b-badge
              pill
              :variant="`light-${getCodeColor('stockup_status',value)}`"
          >
            {{ getCodeLabel('stockup_status', value) }}
          </b-badge>
        </template>

        <template #cell(stockup_type)="data">
          {{(stock_type_code.find(item => item.value === data.item.ext.stockup_type) || "").label}}
        </template>

        <template #cell(purchase_team_id)="{value}">
          {{ getCodeLabel('purchase_team', value) }}
        </template>

        <template #cell(updator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>

        <template #cell(creator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>

        <template #cell(purchase_create_time)="{value}">
          {{ toTime(value) }}
        </template>

        <template #cell(create_time)="{value}">
          {{ toTime(value) }}
        </template>

        <template #cell(update_time)="{value}">
          {{ toTime(value) }}
        </template>

        <!-- 处理备注 -->
        <template #cell(memo)="data">
          <div :id="`memo2-${data.item.id}`" style="width: 55px">
            <span v-if="data.item.memo">
              {{ data.item.memo.substring(0, 3) }}
              <span v-if="data.item.memo.length>3">...</span>
            </span>
          </div>
          <b-tooltip :target="`memo2-${data.item.id}`" placement="top">
            {{ data.item.memo }}
          </b-tooltip>
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
            @click="changeState(data.item)"
          >
          <b-badge
            pill
            :variant="`light-${getCodeColor('state', data.item.state)}`"

          >
            {{getCodeLabel('state', data.item.state)}}
          </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <!--
            <b-dropdown-item @click="showReceiveModal(data.item,3)" v-if="data.item.status === 1 && [14,29,31].includes(user.role_id)">
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">同意</span>
            </b-dropdown-item>
            -->

            <b-dropdown-item @click="showReceiveModal(data.item,4)" v-if="data.item.status === 1 && [14].includes(user.role_id)">
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">同意</span>
            </b-dropdown-item>

            <b-dropdown-item @click="showReceiveModal(data.item,2)" v-if="data.item.status === 1 && [14].includes(user.role_id)">
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">驳回</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

      <b-modal
          id="receiveModal"
          ok-title="确认"
          ok-only
          @ok="updateStockupStatus"
          centered
          size="lg"
          title="备采计划审批意见"
      >
        <b-row>
          <!--备注-->
          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="备注"
                label-for="deal_remark"
                label-size="sm"
                class="mb-1"
            >
              <b-form-textarea
                  id="deal_remark"
                  size="sm"
                  v-model="memo"
                  rows="3"
                  max-rows="6"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>

      <!-- 加载modal -->
      <b-modal
          id="loadingModal"
          centered
          hide-header
          no-close-on-backdrop
          no-close-on-esc
          hide-footer
          :visible="loadingVisible"
      >
        <p class="my-4">正在提交，请稍候!</p>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { oaMenu } from '@/views/apps/oa/oaMenu'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty
} from '@core/utils/filter'
import purchasestockupUseList from './purchasestockupUseList'
import purchasestockupStore from './purchasestockupStore'
import Ripple from "vue-ripple-directive";
import modalSelect from "@/views/components/modal/ModalSelect";
import {useToast} from "vue-toastification/composition";
import purchaseStockupItemList from "@/views/apps/purchasestockupitem/PurchaseStockupItemList";
import {getUserData} from "@/auth/utils";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    modalSelect,
    purchaseStockupItemList,
  },
  data() {
    return {
      isShowCard: false,
      rangeConfig: {
        mode: 'range',
      },
      user: {},
      ps_id: "",
      memo: "",
      status: 0,
      order_id:"",
      loadingVisible: false,
      stock_type_code:[{label:"流货",value:1},{label:"品牌",value:2}],
    }
  },
  directives: {
    Ripple,
  },
  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('purchasestockup/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('purchasestockup')) store.registerModule('purchasestockup', purchasestockupStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchasestockup')) store.unregisterModule('purchasestockup')
    })

    const advanced_search = function(){
      this.isShowCard = !this.isShowCard
    }

    const searchByCondition = function(){
      refetchData()
    }

    const resetCondition = function(){
      this.condition = {}
      refetchData()
    }

    const changeSelect = function (key,param){
      if(!isEmpty(param)){
        this.condition[key] = param.value
      }else {
        this.condition[key] = null
      }
      this.$forceUpdate()
    }

    const fromChildren = function (params, modal, item) {
      for (let i = 0; i < modal.length; i++) {
        item[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const updateStockupStatus = function(){
      this.loadingVisible = true
      store.dispatch('purchasestockup/save',{ps_id:this.ps_id,status:this.status,memo:this.memo,order_id:this.order_id}).then(res => {
        if(res.data.code === 0){
          toast.success("已审核")
        }else{
          toast.error(res.data.data)
        }
        this.loadingVisible = false
        refetchData()
      })
    }

    const showReceiveModal = function (item,status){
      this.ps_id = item.ps_id
      this.memo = item.memo
      this.status = status
      this.order_id = item.order_id
      this.$bvModal.show('receiveModal')
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,

      // UI
    } = purchasestockupUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      advanced_search,
      condition,
      searchByCondition,
      resetCondition,
      changeSelect,
      fromChildren,
      updateStockupStatus,
      showReceiveModal,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      oaMenu,
      // UI
     toTime,
     toDate,
    }
  },
  created() {
    const userData = getUserData()
    this.user = userData
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
