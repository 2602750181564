<template>

  <div>
    <b-card>
      <b-table
        sticky-header
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <template v-slot:custom-foot="data">
          <b-tr v-if="1 === order_stockup_type">
            <b-td :colspan="4" variant="primary">
              <span><strong>小计</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{qty_total}}</strong></span>
            </b-td>
            <b-td :colspan="tableColumns.length" variant="primary">
              <span><strong>{{delivery_qty_total}}</strong></span>
            </b-td>
          </b-tr>
          <b-tr v-if="2 === order_stockup_type">
            <b-td :colspan="4" variant="primary">
              <span><strong>小计</strong></span>
            </b-td>
            <b-td :colspan="12" variant="primary">
              <span><strong>{{qty_total}}</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{coming_30d_can_purchase_amt_total}}</strong></span>
            </b-td>
            <b-td :colspan="4" variant="primary">
              <span><strong>{{sales_30d_tax_amt_total}}</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{subtotal_total}}</strong></span>
            </b-td>
          </b-tr>
        </template>

        <!--商品名称-->
        <template #cell(product_name)="data">
          {{isEmpty(data.item.ext.product_name)?"":data.item.ext.product_name}}
        </template>
        <!--69码-->
        <template #cell(product_code)="data">
          {{isEmpty(data.item.ext.product_code)?"":data.item.ext.product_code}}
        </template>
        <!--规格-->
        <template #cell(product_specification)="data">
          {{isEmpty(data.item.ext.product_specification)?"":data.item.ext.product_specification}}
        </template>
        <!--品牌-->
        <template #cell(brand_name)="data">
          {{isEmpty(data.item.ext.brand_name)?"":data.item.ext.brand_name}}
        </template>
        <!--已入库数量-->
        <template #cell(delivery_qty)="data">
          {{isEmpty(data.item.ext.delivery_qty)?0:data.item.ext.delivery_qty}}
        </template>
        <!--在库数量-->
        <template #cell(inStock_qty)="data">
          {{isEmpty(data.item.ext.inStock_qty)?0:data.item.ext.inStock_qty}}
        </template>
        <!--采购在途-->
        <template #cell(inTransit_qty)="data">
          {{isEmpty(data.item.ext.inTransit_qty)?0:data.item.ext.inTransit_qty}}
        </template>
        <!--预计售罄天数-->
        <template #cell(estimated_soldout_days_30d_avg)="data">
          {{isEmpty(data.item.ext.estimated_soldout_days_30d_avg)?0:data.item.ext.estimated_soldout_days_30d_avg}}
        </template>
        <!--近90天采购数量-->
        <template #cell(purchase_90d_cnt)="data">
          {{isEmpty(data.item.ext.purchase_90d_cnt)?0:data.item.ext.purchase_90d_cnt}}
        </template>
        <!--近90天到货数量-->
        <template #cell(arrival_90d_cnt)="data">
          {{isEmpty(data.item.ext.arrival_90d_cnt)?0:data.item.ext.arrival_90d_cnt}}
        </template>
        <!--到货截点完成率-->
        <template #cell(arrival_before_estimated_percent)="data">
          {{isEmpty(data.item.ext.arrival_before_estimated_percent)?"":(data.item.ext.arrival_before_estimated_percent * 100).toFixed(2) + '%'}}
        </template>
        <!--预计毛利率完成率-->
        <template #cell(estimated_gross_profit_rate_percent)="data">
          {{isEmpty(data.item.ext.estimated_gross_profit_rate_percent)?"":(data.item.ext.estimated_gross_profit_rate_percent * 100).toFixed(2) + '%'}}
        </template>
        <!--销售时效完成率-->
        <template #cell(sold_ontime_product_percent)="data">
          {{isEmpty(data.item.ext.sold_ontime_product_percent)?"":(data.item.ext.sold_ontime_product_percent * 100).toFixed(2) + '%'}}
        </template>
        <!--近90天销售数量-->
        <template #cell(sales_90d_cnt)="data">
          {{isEmpty(data.item.ext.sales_90d_cnt)?0:data.item.ext.sales_90d_cnt}}
        </template>
        <!--平均到货天数-->
        <template #cell(avg_arrival_days)="data">
          {{isEmpty(data.item.ext.avg_arrival_days)?0:data.item.ext.avg_arrival_days}}
        </template>

        <!--流货字段-->
        <!--建议备货数量1-->
        <template #cell(stockup1_cnt)="data">
          {{isEmpty(data.item.ext.stockup1_cnt)?0:data.item.ext.stockup1_cnt}}
        </template>
        <!--建议备货数量2-->
        <template #cell(stockup2_cnt)="data">
          {{isEmpty(data.item.ext.stockup2_cnt)?0:data.item.ext.stockup2_cnt}}
        </template>
        <!--安全库存-->
        <template #cell(safety_stock)="data">
          {{isEmpty(data.item.ext.safety_stock)?0:data.item.ext.safety_stock}}
        </template>
        <!--近90天日均销量-->
        <template #cell(sales_90d_avg_cnt)="data">
          {{isEmpty(data.item.ext.sales_90d_avg_cnt)?0:data.item.ext.sales_90d_avg_cnt}}
        </template>

        <!--品牌字段-->
        <!--未来30天可采金额-->
        <template #cell(coming_30d_can_purchase_amt)="data">
          {{isEmpty(data.item.ext.coming_30d_can_purchase_amt)?0:data.item.ext.coming_30d_can_purchase_amt}}
        </template>
        <!--近30天销售金额-->
        <template #cell(sales_30d_tax_amt)="data">
          {{isEmpty(data.item.ext.sales_30d_tax_amt)?0:data.item.ext.sales_30d_tax_amt}}
        </template>
        <!--未来30天可采数量-->
        <template #cell(coming_30d_can_purchase_cnt)="data">
          {{isEmpty(data.item.ext.coming_30d_can_purchase_cnt)?0:data.item.ext.coming_30d_can_purchase_cnt}}
        </template>
        <!--近30日销量-->
        <template #cell(sales_30d_cnt)="data">
          {{isEmpty(data.item.ext.sales_30d_cnt)?0:data.item.ext.sales_30d_cnt}}
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor,isEmpty } from '@core/utils/filter'
import purchasestockupitemUseList from './purchasestockupitemUseList'
import purchasestockupitemStore from './purchasestockupitemStore'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('purchasestockupitem/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
  },
  props: {
    ps_id: {
      type: Number,
      default: 0
    },
    stockup_type: {
      type: Number,
      default: 0
    },
  },
  setup(props) {
    // Register module
    if (!store.hasModule('purchasestockupitem')) store.registerModule('purchasestockupitem', purchasestockupitemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchasestockupitem')) store.unregisterModule('purchasestockupitem')
    })

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      qty_total,
      delivery_qty_total,
      subtotal_total,
      coming_30d_can_purchase_amt_total,
      sales_30d_tax_amt_total,
      order_stockup_type,

      // UI
    } = purchasestockupitemUseList(props)

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      qty_total,
      delivery_qty_total,
      subtotal_total,
      coming_30d_can_purchase_amt_total,
      sales_30d_tax_amt_total,
      order_stockup_type,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      isEmpty,
      // UI
     toTime,
     toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
