import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function purchasestockupUseList() {
  // Use toast
  const toast = useToast()
  const condition = ref({})
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'ps_id', label: 'ID', sortable: true },
    { key: 'ps_no', label: '备货计划编号', sortable: true },
    { key: 'stockup_type', label: '备采类型' },
    //{ key: 'order_id', label: '采购订单id', sortable: true },
    { key: 'order_no', label: '采购订单编号', sortable: true },
    { key: 'purchase_team_id', label: '销售权团队Id', sortable: true },
    //{ key: 'supplier_id', label: '供应商ID', sortable: true },
    { key: 'supplier_name', label: '供应商名称', sortable: true },
    //{ key: 'applier_id', label: '申请人ID', sortable: true },
    //{ key: 'applier_name', label: '申请人', sortable: true },
    { key: 'creator', label: '创建人', sortable: true },
    { key: 'purchase_create_time', label: '采购单创建日期', sortable: true },
    //{ key: 'state', label: '状态', sortable: true },
    { key: 'status', label: '备采单状态', sortable: true },
    { key: 'memo', label: '备注', sortable: true },
    { key: 'create_time', label: '提交日期', sortable: true },
    //{ key: 'update_time', label: '更新时间', sortable: true },
    //{ key: 'updator', label: '更新人ID', sortable: true },
    { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('ps_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('purchasestockup/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        order_no: condition.value.order_no,
        purchase_team_id: condition.value.purchase_team_id,
        creator: condition.value.creator,
        supplier_id: condition.value.supplier_id,
        status: condition.value.status,
        stockup_type: condition.value.stockup_type,
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    condition,
    refetchData,

    // Extra Filters
  }
}
